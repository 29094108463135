<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="提交状态">
          <el-radio-group v-model="condition.collectState" @change="getPageData()">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="0">未提交</el-radio-button>
            <el-radio-button label="1">已提交</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="离宁日期">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="登记人姓名"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="workType" slot-scope="scope">
          <el-button type="text" @click="$site.view_myCarBorrow_detail(scope.row.borrowId)">{{ scope.row.workType }}</el-button>
        </template>
        <template slot="leaveTime" slot-scope="scope">
          <p>
            {{ scope.row.leaveTime | limitTo(10) }}
          </p>
          <p class="text-cut">
            {{ scope.row.leaveReason }}
          </p>
        </template>
        <template slot="backTime" slot-scope="scope">
          <p>
            {{ scope.row.backTime | limitTo(10) }}
          </p>
          <p class="text-cut">
            {{ scope.row.backFrom }}
          </p>
        </template>
        <template slot="collectState" slot-scope="scope">
          <span v-if="scope.row.collectState == 0" class="text-blue">未提交</span>
          <span v-else-if="scope.row.collectState == 1" class="text-green">已提交</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'MyCarBorrowList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '姓名', prop: 'userName' },
          { label: '电话', prop: 'mobile' },
          { label: '离宁日期/事由', slot: 'leaveTime' },
          { label: '离宁方式', prop: 'leaveTraffic' },
          { label: '返宁时间/出发地', slot: 'backTime' },
          { label: '返宁方式', prop: 'backTraffic' },
          { label: '状态', slot: 'collectState' }
        ],
        operation: {
          width: '160',
          buttons: [{ type: 'primary', icon: 'el-icon-printer', label: '详细信息', click: this.detail }]
        }
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        collectState: '',
        startDate: '',
        endDate: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/viruscollect/list', ['enterpriseCode', this.condition])
    },
    detail(item) {
      this.$site.view_viruscollect_detail(item.collectId)
    }
  }
}
</script>
